import {createApp} from 'vue';
import * as directives from '../directives';

export {default as Search} from './Search.vue';
export {default as HeaderSearchForm} from './HeaderSearchForm.vue';
export {default as SearchResultsForm} from './SearchResultsForm.vue';
export {default as AgenciesMap} from './AgenciesMap.vue';

export function initVueComponents(components) {
    const vueApps = new Map();

    const createVueApp = (el, component, props) => {
        if (vueApps.has(el)) {
            return;
        }
        const app = createApp(component, props);
        for (const directive in directives) {
            app.directive(directive, directives[directive]);
        }
        app.mount(el);
        vueApps.set(el, app);
    };

    const destroyVueApp = el => {
        if (!vueApps.has(el)) {
            return;
        }
        vueApps.get(el).unmount(el);
        vueApps.delete(el);
    };

    document.querySelectorAll('[data-component]').forEach(el => {
        const componentName = el.dataset.component;
        if (!(componentName in components)) {
            console.warn(`Component "${componentName}" not found.`);
            return;
        }
        const component = components[componentName];
        const mediaQuery = el.dataset.componentMediaQuery;
        const props = JSON.parse(el.dataset.props || '{}');
        if (!mediaQuery) {
            createVueApp(el, component, props);
            return;
        }
        const mediaQueryList = window.matchMedia(mediaQuery);
        if (mediaQueryList.matches) {
            createVueApp(el, component, props);
        }
        mediaQueryList.addEventListener('change', event => {
            event.matches ? createVueApp(el, component, props) : destroyVueApp(el);
        });
    });
}
