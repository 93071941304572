<script setup>
import {Canvas, DivIcon, FeatureGroup, GeoJSON, Map, Marker} from 'leaflet';
import * as topojson from 'topojson-client';
import {computed, onMounted, ref} from 'vue';
import countriesJson from '../countries.json';

const {__, sprintf} = wp.i18n;

const props = defineProps({
    agenciesByRegion: {
        type: Array,
        default: () => [],
    },
    isFrontPage: Boolean,
    isFooter: Boolean,
});

const countries = topojson.feature(countriesJson, 'countries');
const agenciesByRegion = ref(props.agenciesByRegion.map(region => {
    region.agencies.map(agency => {
        agency.region = region;
        agency.active = agency.highlighted;
        return agency;
    });
    region.active = false;
    return region;
}));
const activeRegion = computed({
    get: () => agenciesByRegion.value.find(region => region.active),
    set: region => {
        agenciesByRegion.value.forEach(r => r.active = r.id === region.id);
        map.fitBounds(region.markersGroup.getBounds(), {padding: [50, 50], animate: false, maxZoom: region.maxZoom});
        activeAgency.value = region.agencies.find(agency => agency.active);
    },
});
const activeAgency = computed({
    get: () => activeRegion.value?.agencies.find(agency => agency.active),
    set: agency => {
        agency.region.agencies.forEach(a => a.active = a.id === agency.id);
        agency.region.markersGroup.eachLayer(marker => marker._icon.classList.toggle('active', marker.agency.id === agency.id));
    },
});

const mapContainer = ref(null);
/** @type {Map|null} */
let map = null;

function buildMap() {
    return new Map(mapContainer.value, {
        renderer: new Canvas(),
        zoom: 5,
        minZoom: 1,
        maxZoom: 19,
        center: [46.89023157359399, 1.7578125],
        attributionControl: false,
        zoomControl: false,
        dragging: false,
        zoomSnap: 0.1,
        zoomDelta: 0.1,
        keyboard: false,
        scrollWheelZoom: false,
        touchZoom: false,
        layers: [
            new GeoJSON(countries, {
                style: () => ({
                    stroke: true,
                    color: '#262f3d',
                    weight: 1,
                    fill: true,
                    fillColor: '#141F29',
                    fillOpacity: 1,
                }),
                interactive: false,
            }),
            ...(agenciesByRegion.value.map(region => {
                const markersGroup = new FeatureGroup(region.agencies.map(agency => {
                    const marker = new Marker(agency.coordinates, {
                        icon: new DivIcon({
                            iconSize: [10, 10],
                            className: 'agency-marker',
                        }),
                    });
                    marker.agency = agency;
                    marker.on('click', () => activeAgency.value = agency);
                    return marker;
                }));
                region.markersGroup = markersGroup;
                return markersGroup;
            })),
        ],
    });
}

onMounted(() => {
    map = buildMap();
    if (agenciesByRegion.value.length) {
        activeRegion.value = agenciesByRegion.value[0];
    }
});
</script>

<template>
    <div>
        <div class="region-tabs-tablist" :aria-label="__('Régions', 'clever-age')">
            <button v-for="region in agenciesByRegion"
                    type="button"
                    class="region-tabs-btn cta-link tt-u fw-700 p-s fg-1"
                    :class="isFooter ? 'c-white' : 'c-dark'"
                    :aria-selected="region.active"
                    :tabindex="region.active ? '0' : '-1'"
                    @click="activeRegion = region">
                {{ region.label }}
            </button>
        </div>
        <div class="row ai-c mbl-xxxl">
            <div class="col-6">
                <ul v-if="activeRegion"
                    class="agencies-ghost-list visually-hidden"
                    :aria-label="sprintf( __( 'Nos agences en %s', 'clever-age' ), activeRegion.label )">
                    <li v-for="agency in activeRegion.agencies" class="agency">
                        <button type="button">{{ agency.label }}</button>
                    </li>
                </ul>
                <div ref="mapContainer" class="agencies-map"></div>
            </div>
            <div class="col-6">
                <transition mode="out-in">
                    <section
                        class="agency-info bg-white decoration-left-lightest active agency-caption p-m decoration decoration-left-center ta-c"
                        :key="activeAgency.id"
                        v-if="activeAgency">
                        <component :is="isFrontPage ? 'h3': 'p'" class="c-dark tt-6 fw-700">
                            {{ sprintf(__('Agence de %s', 'clever-age'), activeAgency.label) }}
                        </component>
                        <address class="c-dark mt-xl">
                            <span v-if="activeAgency.address" v-html="activeAgency.address"></span>
                            <a v-if="activeAgency.phoneNumber"
                               class="cta-link d-b"
                               :href="`tel:${activeAgency.phoneNumber}`">
                                {{ sprintf(__('Tél : %s', 'clever-age'), activeAgency.phoneNumber) }}
                            </a>
                            <a v-if="activeAgency.email"
                               class="cta-link d-b"
                               :href="`mailto:${activeAgency.email}`">
                                {{ activeAgency.email }}
                            </a>
                            <a class="mt-l d-b c-rum cta-link fw-700" :href="activeAgency.url">
                                {{ __('Voir le détail', 'clever-age') }}
                            </a>
                        </address>
                    </section>
                </transition>
            </div>
        </div>
    </div>
</template>
