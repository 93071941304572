export class Tabs {
    constructor() {
        this.tabs = document.querySelectorAll('[role="tab"]');
        this.tabList = document.querySelector('[role="tablist"]');
        this.event()
    }

    event() {
        if (!this.tabs.length || !this.tabList.length) {
            return;
        }

        this.tabs.forEach((tab) => {
            tab.addEventListener("click", this.changeTabs);
        });

        let tabFocus = 0;

        this.tabList.addEventListener("keydown", (e) => {

            // Move right

            if (e.key === 'ArrowRight' || e.key === 'ArrowLeft') {
                this.tabs[tabFocus].setAttribute("tabindex", -1);
                if (e.key === 'ArrowRight') {
                    tabFocus++;

                    if (tabFocus >= this.tabs.length) {
                        tabFocus = 0;
                    }
                 // Move left
                } else if (e.key === 'ArrowLeft') {
                    tabFocus--;

                    if (tabFocus < 0) {
                        tabFocus = this.tabs.length - 1;
                    }
                }
                console.log(this.tabs[tabFocus])
                this.tabs[tabFocus].setAttribute("tabindex", 0);
                this.tabs[tabFocus].focus();
            }
        });
    }

    changeTabs(e) {
        const target = e.target;
        const parent = target.parentNode;
        const grandparent = parent.parentNode;

        // Remove all current selected tabs
        parent
            .querySelectorAll('[aria-selected="true"]')
            .forEach((t) => {
              t.setAttribute("aria-selected", false);
              t.setAttribute("tabindex", -1);
            });

        // Set this tab as selected
        target.setAttribute("aria-selected", true);
        target.setAttribute("tabindex", 0);

        // Hide all tab panels
        grandparent
            .querySelectorAll('[role="tabpanel"]')
            .forEach((p) => p.setAttribute("hidden", true));

        // Show the selected panel
        grandparent.parentNode
            .querySelector(`#${target.getAttribute("aria-controls")}`)
            .removeAttribute("hidden");
    }
}

